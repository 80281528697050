'use client'
import heroSvg from '@/app/(landing)/landing/images/background-faqs.jpg'
import Messages from '@/app/(landing)/login/messages'
import PageCard from '@/app/components/core/PageCard'
import getClientSupabase from '@/app/utils/getClientSupabase'
import { Button } from '@nextui-org/button'
import { Input } from '@nextui-org/input'
import { Link } from '@nextui-org/react'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { useState, useEffect } from 'react'
import { useError } from '../providers/ErrorProvider'
import {Tabs, Tab, Card, CardBody, CardHeader} from "@nextui-org/react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Checkbox, Chip } from "@nextui-org/react";
import { useRouter } from 'next/navigation'
import { CakeIcon, AtSymbolIcon, FingerPrintIcon } from '@heroicons/react/24/outline'

// Initializing supabase and define state
const supabase = getClientSupabase()

function createSearchParamString(redirectTo: string | null = '', isFirst: boolean, encode = false) {
  if (!redirectTo) return ''
  return `${isFirst?'?':'&'}redirectTo=${(encode===true)?encodeURIComponent(redirectTo):redirectTo}`
}

type Credentials = {
  email: string
}

type Password = {
  password: string
}

export type AuthForm = {
  type: 'form'|'magic link sent'|'registration completed'|'registration'|'password reset'|'password reset requested'|'password set'
}

type CredentialsForm = {
  login: string
  password: string
}

// login or signup
export default function AuthCard({ type }: AuthForm ) {
  const searchParams = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')

  const { showError } = useError()
  const router = useRouter()

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [emailPasswordReset, setEmailPasswordReset] = useState('')
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [supportLink, setSupportLink] = useState<string|undefined>(undefined);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  const [loginRegister, setLoginRegister] = useState('')
  const [password1Register, setPassword1Register] = useState('')
  const [password2Register, setPassword2Register] = useState('')
  const [termsChecked, setTermsChecked] = useState(false)
  const [password1Change, setPassword1Change] = useState('')
  const [password2Change, setPassword2Change] = useState('')

  useEffect(() => {
    // receive logo from cookies or use default
    const currentFullUrl = window.location.host;
    setSupportLink( (currentFullUrl.includes('app.interviewmojito.com')? 'interviewmojito.com': 'jobmojito.com'))
  }, [])

  // LOGIN/SIGNUP FUNCTIONS
  async function signInWithGoogle(redirectTo: string | null = '') {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo:
          `${window.location.origin}/auth/callback` +
          createSearchParamString(redirectTo, true),
      },
    })

    if (error) showError(error.message, 'error');
  }

  async function signInWithApple(redirectTo: string | null = '') {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'apple',
      options: {
        redirectTo:
          `${window.location.origin}/auth/callback` +
          createSearchParamString(redirectTo, true),
      },
    })

    if (error) showError(error.message, 'error');
  }

  async function signInWithLinkedin(redirectTo: string | null = '') {
    const { data, error } = await supabase.auth.signInWithOAuth({
      // supabase types are wrong, this is the correct provider
      // @ts-ignore
      provider: 'linkedin_oidc',
      options: {
        redirectTo:
          `${window.location.origin}/auth/callback` +
          createSearchParamString(redirectTo, true),
      },
    })

    if (error) showError(error.message, 'error');
  }

  const handleLogIn = async ({ email }: Credentials) => {
    if (email === '') {
      showError('Please enter your e-mail address', 'warning')
    } else {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: `${location.origin}/auth/callback` +
          createSearchParamString(redirectTo, true)
        },
      })

      setLoading(false);
      if (error) {
        showError(error.message, 'error');
      } else {
        router.push('/login?type=magic_sent');
      }
    }
  }

  const handleLogInForm = async ({ login, password }: CredentialsForm) => {
    if ((login === '') || (password==='')) {
      showError('Please enter your e-mail address and password', 'warning')
    } else {
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithPassword({
        email: login,
        password: password,
      })

      setLoading(false);
      if (error) {
        showError(error.message, 'error');
      } else {
        supabase.auth.refreshSession({ refresh_token: data.session.refresh_token}).then((refreshSession)=> { 
          router.push('/auth/callback'+
            createSearchParamString(redirectTo, true));
        });
      }
    }
  }

  const handleRegisterForm = async ({ login, password }: CredentialsForm) => {
    if ((login === '') || (password==='')) {
      showError('Please enter your e-mail address and password', 'warning')
    } else {
      setLoading(true);
      const { data, error } = await supabase.auth.signUp({
        email: login,
        password: password,
        options: {
          emailRedirectTo: `${location.origin}/auth/callback`+
          createSearchParamString(redirectTo, true),
        }
      })

      setLoading(false);
      if (error) {
        showError(error.message, 'error');
      } else {
        router.push('/login?type=register_completed');
      }
    }
  }

  const handlePasswordResetForm = async ({ email }: Credentials) => {
    if ((emailPasswordReset==='')) {
      showError('Please enter your e-mail address', 'warning')
    } else {
      setLoading(true);
      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${location.origin}/login?type=password_set`+
        createSearchParamString(redirectTo, false),
      })

      setLoading(false);
      if (error) {
        showError(error.message, 'error');
      } else {
        router.push('/login?type=password_reset_requested');
      }
    }
  }

  const handlePasswordChangeForm = async ({ password }: Password) => {
    if ((password==='')) {
      showError('Please enter your new password', 'warning')
    } else {
      setLoading(true);
      const { data, error } = await supabase.auth.updateUser({
        password: password,
      })

      setLoading(false);
      if (error) {
        showError(error.message, 'error');
      } else {
        router.push('/auth/callback'+
          createSearchParamString(redirectTo, true));
      }
    }
  }

  function handleEnterKey(event: React.KeyboardEvent<HTMLInputElement>) {
    // only for enter
    if (event.key === 'Enter') {
      handleLogIn({ email })
    }
  }

  function handleEnterForm(event: React.KeyboardEvent<HTMLInputElement>) {
    // only for enter
    if (event.key === 'Enter') {
      handleLogInForm({ login, password })
    }
  }

  return (
    <div
      className={
        'w-full flex flex-col items-center relative mt-[4rem]'
      }
    >
      <svg
        className="absolute inset-x-0 top-0 -z-10 h-[40rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect width="100%" height="80%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
      </svg>
      <div
        className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
        aria-hidden="true"
      >
        <div
          className="aspect-[801/1036] w-[10rem] lg:w-[20rem] xl:w-[22rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)',
          }}
        />
      </div>

      <PageCard className={'w-full md:max-w-lg my-8 '}>
        <div className="flex flex-col justify-center text-center gap-3">
          <Messages />
          {(type==='magic link sent') && <div className={'text-lg text-semibold'}>
            <div className="text-left text-black text-md mt-3 flex flex-col gap-5">
              <div className='text-3xl font-bold'><AtSymbolIcon className='text-success w-8 h-8' />Login email sent</div>
              <div>We’ve sent an email with a temporary access link. Please check your email for a confirmation link to proceed.</div>
              <div className='text-sm text-gray-600'>Note: If you don't see the email, it might be in your spam folder.</div>
              <div><Link href='/login' size='sm' color='success'>Back to the Login page</Link></div>
            </div>
          </div>}

          {(type==='registration completed') && <div className={'text-lg text-semibold'}>
            <div className="text-left text-black text-md mt-3 flex flex-col gap-5">
              <div className='text-3xl font-bold'><CakeIcon className='text-success w-8 h-8' />Account created</div>
              <div>We just sent you a confirmation email. Click the link to activate your account.</div>
              <div className='text-sm text-gray-600'>Note: If you don't see the email, it might be in your spam folder.</div>
              <div><Link href='/login' size='sm' color='success'>Back to the Login page</Link></div>
            </div>
          </div>}

          {(type==='password reset requested') && <div className={'text-lg text-semibold'}>
            <div className="text-left text-black text-md mt-3 flex flex-col gap-5">
              <div className='text-3xl font-bold'><FingerPrintIcon className='text-success w-8 h-8' />Password reset Email sent</div>
              <div>We’ve sent an email with a temporary access link. Please check your email for a confirmation link to proceed.</div>
              <div className='text-sm text-gray-600'>Note: If you don't see the email, it might be in your spam folder.</div>
              <div><Link href='/login' size='sm' color='success'>Back to the Login page</Link></div>
            </div>
          </div>}

          {(type==='password reset') && <div className={'text-lg text-semibold'}>
            <div className="text-left text-md mt-3 flex flex-col gap-5">
              <div className='text-left text-black'>
                <div className='text-lg font-bold'>Reset your password</div>
                <div className='text-sm font-light text-gray-600'>Forgot your password? Enter your email below, and we'll send you a password reset link.</div>
              </div>
              <div className='flex flex-col gap-3'>
                <Input
                  aria-label='Email Password reset'
                  radius={'sm'}
                  type="email"
                  label="Email"
                  isRequired
                  labelPlacement={'inside'}
                  placeholder="Enter your email"
                  onChange={(e) => setEmailPasswordReset(e.target.value)}
                  value={emailPasswordReset}
                  autoComplete='username'
                  name="login"
                />

                <Button color="primary" isLoading={loading} onPress={() => { handlePasswordResetForm({email: emailPasswordReset})} } isDisabled={
                  (emailPasswordReset==='') 
                  } >
                  Send password reset link 
                </Button>
                <div><Link href={'/login'+createSearchParamString(redirectTo, true, true)} size='sm' color='primary'>Back to the Login page</Link></div>

              </div>
            </div>
          </div>}

          {(type==='password set') && <div className={'text-lg text-semibold'}>
            <div className="text-left text-md mt-3 flex flex-col gap-5">
              <div className='text-left text-black'>
                <div className='text-lg font-bold'>Set your new Passowrd</div>
              </div>
              <div className='flex flex-col gap-3'>
                <Input
                    aria-label='New password'
                    radius={'sm'}
                    type="password"
                    label="Password"
                    isRequired
                    labelPlacement={'inside'}
                    placeholder="Enter new password"
                    onChange={(e) => setPassword1Change(e.target.value)}
                    value={password1Change}
                    autoComplete="new-password"
                    name="new-password"
                  />
                  <Input
                    aria-label='New password 2'
                    radius={'sm'}
                    type="password"
                    label="Repeat password"
                    isRequired
                    labelPlacement={'inside'}
                    placeholder="Repeat password"
                    onChange={(e) => setPassword2Change(e.target.value)}
                    value={password2Change}
                    autoComplete="new-password"
                    name="new-password-2"
                  />

                  {((password1Change!=='') && (password2Change!=='') && (password1Change!==password2Change)) && <Chip color='warning' variant='flat'>Passwords do not match</Chip> }
                  <Button color="primary" isLoading={loading} onPress={() => { handlePasswordChangeForm({ password:password1Change})} } isDisabled={
                    (password1Change==='') || (password2Change==='') || (password1Change!==password2Change)
                    } >
                    Change password
                  </Button>
                <div><Link href={'/login'+createSearchParamString(redirectTo, true, true)} size='sm' color='primary'>Back to the Login page</Link></div>

              </div>
            </div>
          </div>}

          {(type==='registration') && <div className={'text-lg text-semibold'}>
            <div className="text-left text-md mt-3 flex flex-col gap-5">
              <div className='text-left text-black'>
                <div className='text-lg font-bold'>Register new account</div>
                <div className='text-sm font-light text-gray-600'>Welcome to our platform! After creating your account, you will receive a one-time confirmation email.</div>
              </div>
              <div className='flex flex-col gap-3'>
                <Input
                  aria-label='Email Register'
                  radius={'sm'}
                  type="email"
                  label="Email"
                  isRequired
                  labelPlacement={'inside'}
                  placeholder="Enter your email"
                  onChange={(e) => setLoginRegister(e.target.value)}
                  value={loginRegister}
                  autoComplete='username'
                  name="login"
                />
                <Input
                  aria-label='Password 1 Register'
                  radius={'sm'}
                  type="password"
                  label="Password"
                  isRequired
                  labelPlacement={'inside'}
                  placeholder="Enter new password"
                  onChange={(e) => setPassword1Register(e.target.value)}
                  value={password1Register}
                  autoComplete="new-password"
                  name="new-password"
                />
                <Input
                  aria-label='Password 2 Register'
                  radius={'sm'}
                  type="password"
                  label="Repeat password"
                  isRequired
                  labelPlacement={'inside'}
                  placeholder="Repeat password"
                  onChange={(e) => setPassword2Register(e.target.value)}
                  value={password2Register}
                  autoComplete="new-password"
                  name="new-password-2"
                />
                <Checkbox 
                  size='sm' 
                  className='text-sm'
                  onValueChange={(e) => setTermsChecked(e)}
                >I'm accepting these <Link href='https://jobmojito.com/terms_of_service' size='sm'>Terms of service</Link></Checkbox>
                
                {((password1Register!=='') && (password2Register!=='') && (password1Register!==password2Register)) && <Chip color='warning' variant='flat'>Passwords do not match</Chip> }
                <Button color="primary" isLoading={loading} onPress={() => { handleRegisterForm({login:loginRegister, password:password1Register})} } isDisabled={
                  (termsChecked===false) || (loginRegister==='') || (password1Register==='') || (password2Register==='') || (password2Register!==password1Register)
                  } >
                  Create new account
                </Button>
                <div><Link href={'/login'+createSearchParamString(redirectTo, true, true)} size='sm' color='primary'>Back to the Login page</Link></div>

              </div>
            </div>
          </div>}

          {(type==='form') &&
            <>
              <Tabs aria-label="Disabled Options" variant='underlined' color='secondary' fullWidth>
                <Tab key="photos" title="Single click login">
                  <div className='text-left mb-5 text-black'>
                    <div className='text-lg font-bold'>Login or Register</div>
                    <div className='text-sm font-light text-gray-600'>We'll send you a link to log in. If this is your first time, an account will be created automatically.</div>
                  </div>
                  <div className={'text-left '}>
                    <Input
                      aria-label='Email'
                      className={'mb-3'}
                      radius={'sm'}
                      type="email"
                      label="Email"
                      isRequired
                      labelPlacement={'inside'}
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      name="email"
                      onKeyUp={handleEnterKey}
                    />
                  </div>
                  <Button
                    color={'primary'}
                    radius={'sm'}
                    isDisabled={((email===undefined) || (email===''))}
                    isLoading={loading}
                    onPress={() => handleLogIn({ email })}
                  >
                    Send me the link
                  </Button>
                </Tab>
                <Tab key="pa" title="Email & password login">
                <div className={'text-left mb-2'}>
                    <div className='text-left mb-5 text-black'>
                      <div className='text-lg font-bold'>Login</div>
                      <div className='text-sm font-light text-gray-600'>
                        Prefer traditional username and password? Use this form. Need to create an account? {" "}
                        <Link size="sm" href={'/login?type=register'+createSearchParamString(redirectTo, false, true)} underline="hover" className='cursor-pointer'>
                          Register
                        </Link>.
                      </div>
                    </div>

                    <Input
                      aria-label='Email Login'
                      className={'mb-3'}
                      radius={'sm'}
                      type="email"
                      label="Email"
                      isRequired
                      labelPlacement={'inside'}
                      placeholder="Enter your email"
                      onChange={(e) => setLogin(e.target.value)}
                      value={login}
                      name="Email login"
                      onKeyUp={handleEnterForm}
                    />
                    <Input
                      aria-label='Password Login'
                      className={'mb-3'}
                      radius={'sm'}
                      isRequired
                      type="password"
                      label="Password"
                      labelPlacement={'inside'}
                      placeholder="Enter your password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      name="password"
                      onKeyUp={handleEnterForm}
                    />
                    <p className="text-center text-small text-black">
                    </p>
                  </div>
                  <Button
                    color={'primary'}
                    radius={'sm'}
                    className='mb-5'
                    isLoading={loading}
                    isDisabled={((login===undefined) || (login==='') || (password===undefined) || (password===''))}
                    onPress={() => handleLogInForm({ login, password })}
                  >
                    Login
                  </Button>

                  <div
                    className={
                      'text-sm text-gray-600 font-light'
                    }
                  >
                    Forgot your password? {" "}
                    <Link size="sm" href={'/login?type=password_reset'+createSearchParamString(redirectTo, false, true)} underline="hover" className='cursor-pointer'>
                      Reset Password
                    </Link>.
                  </div>
                </Tab>
              </Tabs>
              
              <div
                className={
                  'text-sm text-black border-t-neutral-900 border-t-1 pt-2'
                }
              >
                Or continue with
              </div>

              <button
                onClick={() => signInWithGoogle(redirectTo)}
                className="flex justify-center items-center bg-white border border-gray-300 rounded-lg px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <svg
                  className="h-6 w-6 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="800px"
                  height="800px"
                  viewBox="-0.5 0 48 48"
                  version="1.1"
                >
                  <title>Google-color</title>
                  <defs> </defs>
                  <g
                    id="Icons"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Color-"
                      transform="translate(-401.000000, -860.000000)"
                    >
                      <g
                        id="Google"
                        transform="translate(401.000000, 860.000000)"
                      >
                        <path
                          d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                          id="Fill-1"
                          fill="#FBBC05"
                        ></path>{' '}
                        <path
                          d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                          id="Fill-2"
                          fill="#EB4335"
                        ></path>{' '}
                        <path
                          d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                          id="Fill-3"
                          fill="#34A853"
                        ></path>{' '}
                        <path
                          d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                          id="Fill-4"
                          fill="#4285F4"
                        ></path>{' '}
                      </g>{' '}
                    </g>{' '}
                  </g>{' '}
                </svg>
                <span>Continue with Google</span>
              </button>

              <button
                onClick={() => signInWithLinkedin(redirectTo)}
                className="flex justify-center items-center bg-white border border-gray-300 rounded-lg px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <svg
                  className="h-6 w-6 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 -2 44 44"
                  version="1.1"
                >
                  <g
                    id="Icons-LinkedIn"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Color-LinkedIn"
                      transform="translate(-702.000000, -265.000000)"
                      fill="#007EBB"
                    >
                      <path
                        d="M746,305 L736.2754,305 L736.2754,290.9384 C736.2754,287.257796 734.754233,284.74515 731.409219,284.74515 C728.850659,284.74515 727.427799,286.440738 726.765522,288.074854 C726.517168,288.661395 726.555974,289.478453 726.555974,290.295511 L726.555974,305 L716.921919,305 C716.921919,305 717.046096,280.091247 716.921919,277.827047 L726.555974,277.827047 L726.555974,282.091631 C727.125118,280.226996 730.203669,277.565794 735.116416,277.565794 C741.21143,277.565794 746,281.474355 746,289.890824 L746,305 L746,305 Z M707.17921,274.428187 L707.117121,274.428187 C704.0127,274.428187 702,272.350964 702,269.717936 C702,267.033681 704.072201,265 707.238711,265 C710.402634,265 712.348071,267.028559 712.41016,269.710252 C712.41016,272.34328 710.402634,274.428187 707.17921,274.428187 L707.17921,274.428187 L707.17921,274.428187 Z M703.109831,277.827047 L711.685795,277.827047 L711.685795,305 L703.109831,305 L703.109831,277.827047 L703.109831,277.827047 Z"
                        id="LinkedIn"
                      ></path>
                    </g>
                  </g>
                </svg>
                <span>Continue with LinkedIn</span>
              </button>

              <button
                type="button"
                onClick={() => signInWithApple(redirectTo)}
                className="text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex justify-center items-center dark:focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30"
              >
                <svg
                  className="w-5 h-5 mr-2 -ml-1"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="apple"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  ></path>
                </svg>
                Continue with Apple
              </button>
              {supportLink!==undefined &&
              <>
                <div className={'text-gray-600 text-sm'}>
                  By continuing, you agree to our{' '}
                  <Link
                    href={'https://'+supportLink+'/terms_of_service'}
                    target="_blank"
                    className="text-primary underline text-sm"
                  >
                    Terms of Use and Privacy Policy.
                  </Link>
                </div>
                <div
                  className={'text-sm border-t-neutral-900 border-t-1 pt-4 pb-1'}
                >
                  Do you have any problems with login / registration, <Link
                    href={'https://'+supportLink+'/support'}
                    target="_blank"
                    className="text-primary underline text-sm"
                  >
                    Contact us
                  </Link>. We are here for you!
                </div>
              </>
              }
            </>
          }
        </div>
      </PageCard>
    </div>
  )
}
