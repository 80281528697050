import { Card } from '@nextui-org/card'
import React from 'react'

export default function PageCard({
  children,
  className = '',
  id,
  isPadded = true,
  isVisible = true
}: {
  children: React.ReactNode
  className?: string
  id?: string
  isPadded?: boolean
  isVisible?: boolean
}) {
  return (
    <Card
      id={id}
      shadow={(isVisible?'sm':'none')}
      className={'w-full rounded-none '+ (isVisible?'md:rounded-xl':'')+' '+((isPadded && isVisible)&&'px-3 py-3 md:px-6 md:py-5')+' ' + className}
    >
      {children}
    </Card>
  )
}
